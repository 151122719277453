import { countUnread, getAllNotifications, markAllAsRead, markAsRead, markAsReadByIds } from "../apis/notification";

export default class NotificationService {
  static async getAll(skip: number) {
    const { data } = await getAllNotifications(skip);
    return data;
  }

  static async markAsReadByIds(ids: string[]) {
    await markAsReadByIds(ids);
  }

  static async markAllAsRead() {
    await markAllAsRead();
  }

  static async markAsRead(id: string) {
    await markAsRead(id);
  }

  static async countUnread() {
    const { data } = await countUnread();
    return data;
  }
}
