import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { Type } from "../components/ui/toast/Toast";
import GoalService from "../services/Goal";
import { GoalsType, IGoal } from "../types/IGoal";
import { errorMessage } from "../utils/general";
import { useAuthContext } from "./AuthProvider";
import { useToast } from "./ToastProvider";

export enum ViewTypes {
  List = "list",
  Grid = "grid",
}

type GoalContextProps = {
  goals: IGoal[];
  loaded: boolean;
  goalExpertiseView: ViewTypes;
  changeGoalExpertiseView: (view: ViewTypes) => void;
  getGoals: (goalsType?: GoalsType) => void;
};

type GoalProviderProps = {
  children: ReactNode;
};

const GoalContext = createContext<GoalContextProps>({
  goals: [],
  loaded: false,
  goalExpertiseView: ViewTypes.List,
  changeGoalExpertiseView: (view: ViewTypes) => {},
  getGoals: (goalsType?: GoalsType) => {},
});

export const GoalProvider: FC<GoalProviderProps> = ({ children }) => {
  const [goals, setGoals] = useState<IGoal[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [goalExpertiseView, setGoalExpertiseView] = useState<ViewTypes>(ViewTypes.Grid);

  const { show } = useToast();
  const { user } = useAuthContext();

  const getGoals = useCallback(
    async (goalsType?: GoalsType) => {
      if (!user) return;

      try {
        setLoaded(false);
        const storedGoalsType = await GoalService.getGoalsTypeToken(user.id);

        const goalsTypeValue = goalsType || storedGoalsType || GoalsType.All;

        const evaluatedGoalsType =
          goalsTypeValue === GoalsType.Completed
            ? GoalsType.Completed
            : goalsTypeValue === GoalsType.InProgress
            ? GoalsType.InProgress
            : GoalsType.All;

        const goals = await GoalService.getGoalsWithoutParent(evaluatedGoalsType);

        // conditions when goalsType token needs to be set
        if (!((storedGoalsType && !goalsType) || (storedGoalsType && goalsType && storedGoalsType === goalsType))) {
          GoalService.setGoalsTypeToken(user.id, evaluatedGoalsType);
        }

        setGoals(goals);
      } catch (err: any) {
        console.error(err);
        show({ type: Type.error, message: errorMessage(err) });
      } finally {
        setLoaded(true);
      }
    },
    [user, show]
  );

  const changeGoalExpertiseView = useCallback(
    (view: ViewTypes) => {
      if (!user) return;

      setGoalExpertiseView(view);
      GoalService.setViewTypeToken(user.id, view);
    },
    [user]
  );

  useEffect(() => {
    const loadViewType = async () => {
      if (!user) return;

      const viewTypeStored = await GoalService.getViewTypeToken(user.id);

      let view = ViewTypes.Grid;

      if (viewTypeStored && viewTypeStored !== view) {
        view = viewTypeStored === ViewTypes.Grid ? ViewTypes.Grid : ViewTypes.List;
      }

      changeGoalExpertiseView(view);
    };

    loadViewType();
  }, [user, changeGoalExpertiseView]);

  useEffect(() => {
    if (user) getGoals();
  }, [user, getGoals]);

  return (
    <GoalContext.Provider value={{ goals, loaded, goalExpertiseView, changeGoalExpertiseView, getGoals }}>
      {children}
    </GoalContext.Provider>
  );
};

export const useGoalContext = () => useContext(GoalContext);
