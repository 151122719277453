import { Dispatch, FC, Fragment, SetStateAction, useCallback, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import parse, { domToReact } from "html-react-parser";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { classNames, errorMessage } from "../../../utils/general";
import { Link } from "react-router-dom";
import NotificationService from "../../../services/Notification";
import Alert, { AlertType } from "../alert/Alert";
import { INotification } from "../../../types/INotification";
import { useToast } from "../../../context/ToastProvider";
import { Type } from "../toast/Toast";
import Loader from "../loader/Loader";
import { useAuthContext } from "../../../context/AuthProvider";

type NotificationSliderProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

let isFirstTime = true;

const NotificationSlider: FC<NotificationSliderProps> = ({ open, setOpen }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);

  const { show } = useToast();
  const { loadUnreadNotificationsCount } = useAuthContext();

  const getNotifications = useCallback(
    async (skip?: number) => {
      try {
        setLoading(true);
        setHasMore(true);

        const skipEntries = skip ?? notifications.length;

        const newNotifications = await NotificationService.getAll(skipEntries);

        if (newNotifications.length !== 15) setHasMore(false);

        setNotifications(
          skip === 0 ? newNotifications : (currNotifications) => [...currNotifications, ...newNotifications]
        );
      } catch (err: any) {
        show({ type: Type.error, message: errorMessage(err) });
      } finally {
        setLoading(false);
      }
    },
    [notifications.length, show]
  );

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: loading,
    hasNextPage: hasMore,
    onLoadMore: getNotifications,
  });

  useEffect(() => {
    // Whenever the NotificationSlider is closed, we should fresh start
    if (!open && !isFirstTime) isFirstTime = true;

    if (!open || !isFirstTime) return;

    getNotifications(0);
    isFirstTime = false;
  }, [getNotifications, open]);

  // Marking each notification as read whenever the NotificationSlider gets closed
  useEffect(() => {
    const closeNotificationSlider = async () => {
      if (open) return;

      const unreadNotificationsIds: string[] = [];

      notifications.forEach((notification) => {
        if (notification.read) return;

        unreadNotificationsIds.push(notification.id);
        notification.read = true;
      });

      if (unreadNotificationsIds.length === 0) return;

      await NotificationService.markAsReadByIds(unreadNotificationsIds);
      loadUnreadNotificationsCount();
    };

    closeNotificationSlider();
  }, [open, notifications, loadUnreadNotificationsCount]);

  const options = {
    replace: (domNode: any) => {
      if (!domNode.attribs || !domNode.attribs.href) return;

      return <Link to={domNode.attribs.href}>{domToReact(domNode.children)}</Link>;
    },
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-auto bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">All Notifications</Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div ref={rootRef} className="relative mt-6 flex-1 overflow-y-auto">
                      {notifications.map((notification) => (
                        <div
                          key={notification.id}
                          onClick={() => setOpen(false)}
                          className={classNames(
                            !notification.read ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm border-b-[1px] hover:bg-gray-100"
                          )}
                        >
                          <div>
                            {
                              // @ts-ignore
                              parse(notification.description, options)
                            }
                          </div>

                          <div className="text-gray-500 mt-2">
                            {moment(notification.createdAt).format("DD MMM YYYY [at] hh:mm A")}
                          </div>
                        </div>
                      ))}

                      {!loading && notifications.length === 0 && <Alert type={AlertType.Info}>No notification</Alert>}

                      {(loading || hasMore) && (
                        <div ref={sentryRef}>
                          <Loader />
                        </div>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default NotificationSlider;
