import { ChangeEvent, FC, useCallback, useState } from "react";

import { useToast } from "../../../context/ToastProvider";
import { Type } from "../../ui/toast/Toast";
import { errorMessage } from "../../../utils/general";
import UserService from "../../../services/User";
import SearchBar from "../../ui/searchbar/SearchBar";

type UserData = {
  id: string;
  name: string;
  avatar: string;
};

const SearchUserList: FC = () => {
  const [users, setUsers] = useState<UserData[]>([]);
  const [searchText, setSearchText] = useState<string>("");

  const [hasMoreUsers, setHasMoreUsers] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { show } = useToast();

  const onChangeSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    const typedText = event.target.value;

    if (!typedText.length || typedText.length > 2) {
      setUsers([]);
      setSearchText(typedText);
      loadUsers(0, typedText);
    }
  };

  const loadUsers = useCallback(
    async (skip?: number, search?: string) => {
      try {
        setIsLoading(true);
        setHasMoreUsers(true);

        const skipEntries = skip ?? users.length;
        const searchQuery = search ?? searchText;

        const newUsers = await UserService.getUsers(skipEntries, searchQuery);

        if (newUsers.length !== 15) setHasMoreUsers(false);

        setUsers((currUsers) => [...currUsers, ...newUsers]);
      } catch (err: any) {
        console.error(err);
        show({ type: Type.error, message: errorMessage(err) });
      } finally {
        setIsLoading(false);
      }
    },
    [show, users.length, searchText]
  );

  return (
    <SearchBar
      searchList={users}
      loading={isLoading}
      loadMore={loadUsers}
      hasMore={hasMoreUsers}
      onChange={onChangeSearchText}
    />
  );
};

export default SearchUserList;
