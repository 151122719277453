import { endpointUrl } from "../utils/general";
import axiosIntance from "./axios";

export const signin = (payload: any) => {
  return axiosIntance.post(endpointUrl("auth/signin"), payload);
};

export const logout = () => {
  return axiosIntance.post(endpointUrl("auth/logout"));
};
