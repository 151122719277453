import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { ChangeEvent, FC } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { Link } from "react-router-dom";
import { ROUTE_USER } from "../../../utils/constants";
import Loader from "../loader/Loader";

type ISearchList = {
  id: string;
  name: string;
  avatar: string;
};

type ISearchBar = {
  searchList?: ISearchList[];
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  loadMore?: () => void;
  hasMore?: boolean;
  loading?: boolean;
};

const SearchBar: FC<ISearchBar> = ({ searchList, onChange, value, loadMore, hasMore, loading }) => {
  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: loading || false,
    hasNextPage: hasMore || false,
    onLoadMore: loadMore || (() => {}),
    // rootMargin: "0px 0px 0px 0px",
  });

  return (
    <div className="h-full flex flex-col gap-1.5">
      <div className="w-full h-fit">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative text-gray-400 focus-within:text-gray-600 mx-0.5">
          <input
            id="search"
            className="block w-full rounded-md border border-transparent bg-gray-100 py-2 pl-3 pr-10 leading-5 text-gray-900 placeholder-gray-500 focus:border-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-brand-500 sm:text-sm"
            placeholder="Search"
            type="text"
            value={value}
            onChange={onChange || undefined}
            onKeyDown={(e) => e.code === "Space" && e.stopPropagation()}
          />

          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
          </div>
        </div>
      </div>

      {searchList && loadMore && (
        <div ref={rootRef} className="flex flex-col gap-1.5 overflow-y-auto" id="searchlist">
          {searchList.map((item) => (
            <Link to={`${ROUTE_USER}/${item.id}`} key={item.id}>
              <div className="w-full text-xs flex items-center gap-4 bg-gray-100 hover:bg-gray-200 rounded-md px-5 py-2">
                <span className="shrink-0">
                  <img
                    src={`${process.env.REACT_APP_IMAGE_ENDPOINT}/${item.avatar}`}
                    alt="user"
                    className="h-[22px] w-[22px] rounded-full"
                  />
                </span>

                <span className="truncate">{item.name}</span>
              </div>
            </Link>
          ))}

          {(loading || hasMore) && (
            <div ref={sentryRef} className="h-9">
              <Loader />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
