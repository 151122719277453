// Routes
export const ROUTE_SIGNIN = "/sign-in";
export const ROUTE_SIGNUP = "/sign-up";
export const ROUTE_USER = "/user";
export const ROUTE_GOALS = "/goals";

export const DEFAULT_AVATAR = "default-avatar.png";

// Success Messages
export const SUCCESS_SIGNIN = "Signed in successfully";
export const SUCCESS_SIGNUP = "Signed up successfully!";

// Error Messages
export const ERROR_COMMON = "Something went wrong. Please try again later";
export const ERROR_CREDENTIALS = "Invalid credentials!";
export const ERROR_SYSTEM = "We are experiencing a problem. Please try again later";
