import { endpointUrl } from "../utils/general";
import axiosIntance from "./axios";

export const getAllNotifications = (skip: number) => {
  return axiosIntance.get(endpointUrl(`notifications?skip=${skip}`));
};

export const markAsReadByIds = (ids: string[]) => {
  return axiosIntance.patch(endpointUrl("notifications/mark-as-read"), ids);
};

export const markAllAsRead = () => {
  return axiosIntance.patch(endpointUrl("notifications/mark-as-read/all"));
};

export const markAsRead = (id: string) => {
  return axiosIntance.patch(endpointUrl(`notifications/mark-as-read/${id}`));
};

export const countUnread = () => {
  return axiosIntance.get(endpointUrl("notifications/count-unread"));
};
