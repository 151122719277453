import axios from "axios";
import AuthService from "../services/Auth";
import { endpointUrl } from "../utils/general";

const axiosIntance = axios.create();

axiosIntance.interceptors.request.use(
  async function (config) {
    config.withCredentials = true;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosIntance.interceptors.response.use(
  (response) => response,
  async function (error) {
    const prevRequest = error?.config;
    const token = AuthService.getToken();

    if (error?.response?.status === 401 && token && !prevRequest?.sent) {
      prevRequest.sent = true;

      const { data } = await axios.post(endpointUrl("auth/refresh"), {}, { withCredentials: true });

      AuthService.setToken(data.accessToken);

      return axiosIntance(prevRequest);
    }

    return Promise.reject(error);
  }
);

export default axiosIntance;
