import { concat, isArray, isObject, without } from "lodash";
import moment from "moment";
import { ERROR_COMMON, ERROR_CREDENTIALS, ERROR_SYSTEM } from "./constants";

export const getClasses = (
  defaultClass: string[] | string,
  appendClass?: string[] | string,
  removeClass?: string[] | string
) => {
  defaultClass = !isArray(defaultClass) ? (defaultClass as string).split(" ") : defaultClass;
  appendClass = !isArray(appendClass) && appendClass ? (appendClass as string).split(" ") : appendClass;
  removeClass = !isArray(removeClass) && removeClass ? (removeClass as string).split(" ") : (removeClass as string[]);

  const allClasses = concat(defaultClass || [], appendClass || []);
  return without(allClasses, ...(removeClass || [])).join(" ");
};

export const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

export const endpointUrl = (url: string | undefined) => {
  return `${process.env.REACT_APP_API_ENDPOINT}/${url}`;
};

export const errorMessage = (error?: any) => {
  let errMsg = ERROR_COMMON;

  switch (error?.response?.status) {
    case 401:
      errMsg = error.response.data.message || ERROR_CREDENTIALS;
      break;
    case 502:
    case 404:
      errMsg = ERROR_SYSTEM;
      break;
    default:
      let err = ERROR_SYSTEM;

      if (error.response?.data?.message) {
        err = error.response?.data?.message;
      } else if (error.response?.data && !isObject(error.response?.data)) {
        err = error.response?.data;
      }

      errMsg = err;
      break;
  }

  return errMsg;
};

export const checkDeadlineExceeded = (deadline: string) => {
  const deadlineDate = moment(deadline).format("YYYY-MM-DD");
  const currDate = moment().format("YYYY-MM-DD");

  return deadlineDate < currDate;
};
