import { lazy, Suspense } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Navigate, Route, Routes } from "react-router-dom";
import SidebarLayout from "./components/layout/SidebarLayout";

import Loader from "./components/ui/loader/Loader";
import { ROUTE_GOALS, ROUTE_SIGNIN, ROUTE_SIGNUP, ROUTE_USER } from "./utils/constants";

const SignIn = lazy(() => import("./pages/sign-in/SignIn"));
const SignUp = lazy(() => import("./pages/sign-up/SignUp"));
const UserDetail = lazy(() => import("./pages/user-detail/UserDetail"));
const Goals = lazy(() => import("./pages/goals/Goals"));
const GoalDetails = lazy(() => import("./pages/goal-details/GoalDetails"));

const fallbackLoader = () => (
  <div className="h-screen w-screen flex justify-center items-center">
    <Loader message="Loading" />
  </div>
);

function App() {
  return (
    <div>
      <Suspense fallback={fallbackLoader()}>
        <Routes>
          <Route path={ROUTE_SIGNIN} element={<SignIn />} />
          <Route path={ROUTE_SIGNUP} element={<SignUp />} />

          <Route element={<SidebarLayout />}>
            <Route path={`${ROUTE_USER}/:id`} element={<UserDetail />} />

            <Route path={ROUTE_GOALS} element={<Goals />} />

            <Route path={`${ROUTE_GOALS}/:id`} element={<GoalDetails />} />
          </Route>
          <Route path="*" element={<Navigate to={ROUTE_SIGNIN} />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
