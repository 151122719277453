import { GoalsType } from "../types/IGoal";
import { endpointUrl } from "../utils/general";
import axiosIntance from "./axios";

export const createGoal = (payload: any) => {
  return axiosIntance.post(endpointUrl("goals"), payload);
};

export const getGoals = () => {
  return axiosIntance.get(endpointUrl("goals"));
};

export const getSelectParentOptions = (id?: string) => {
  return axiosIntance.get(endpointUrl(`goals/select-options/${id}`));
};

export const getGoal = (id: string) => {
  return axiosIntance.get(endpointUrl(`goals/${id}`));
};

export const getGoalsByParentId = (id: string) => {
  return axiosIntance.get(endpointUrl(`goals?parent=${id}`));
};

export const updateGoal = (id: string, payload: any) => {
  return axiosIntance.patch(endpointUrl(`goals/${id}`), payload);
};

export const deleteGoal = (id: string) => {
  return axiosIntance.delete(endpointUrl(`goals/${id}`));
};

export const getInProgressGoals = (userId: string) => {
  return axiosIntance.get(endpointUrl(`goals/in-progress/${userId}`));
};

export const getCompletedGoals = (userId: string) => {
  return axiosIntance.get(endpointUrl(`goals/completed/${userId}`));
};

export const getMinDeadline = (id: string) => {
  return axiosIntance.get(endpointUrl(`goals/min-deadline/${id}`));
};

export const getGoalsWithoutParent = (goalsType: GoalsType) => {
  return axiosIntance.get(endpointUrl(`goals/without-parent?type=${goalsType}`));
};
