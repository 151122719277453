import moment from "moment";
import * as jose from "jose";

import {
  createGoal,
  deleteGoal,
  getCompletedGoals,
  getGoal,
  getGoals,
  getGoalsByParentId,
  getGoalsWithoutParent,
  getInProgressGoals,
  getMinDeadline,
  getSelectParentOptions,
  updateGoal,
} from "../apis/goal";
import AuthService from "./Auth";
import { ViewTypes } from "../context/GoalProvider";
import { GoalsType } from "../types/IGoal";

export default class GoalService {
  static async createGoal(payload: any) {
    const { data } = await createGoal(payload);
    return data;
  }

  static async getGoals() {
    const { data } = await getGoals();
    return data;
  }

  static async getSelectParentOptions(id?: string) {
    const { data } = await getSelectParentOptions(id);
    return data;
  }

  static async getGoal(id: string) {
    const { data } = await getGoal(id);
    return data;
  }

  static async getGoalsByParentId(id: string) {
    const { data } = await getGoalsByParentId(id);
    return data;
  }

  static updateGoal(id: string, payload: any) {
    return updateGoal(id, payload);
  }

  static async deleteGoal(id: string) {
    await deleteGoal(id);
  }

  static async getInProgressGoals(userId: string) {
    const { data } = await getInProgressGoals(userId);
    return data;
  }

  static async getCompletedGoals(userId: string) {
    const { data } = await getCompletedGoals(userId);
    return data;
  }

  static async getMinDeadline(id: string) {
    const { data } = await getMinDeadline(id);
    return data;
  }

  static async getGoalsWithoutParent(goalsType: GoalsType) {
    const { data } = await getGoalsWithoutParent(goalsType);
    return data;
  }

  static async markAsCompleted(id: string) {
    const payload = { finishDate: moment().utc().format() };

    return updateGoal(id, payload);
  }

  static getViewTypeTokenKey(userId: string) {
    return { sub: `view-${userId}` };
  }

  static async setViewTypeToken(userId: string, viewType: ViewTypes) {
    const [tokenKey, tokenValue] = await Promise.all([
      AuthService.generateToken(this.getViewTypeTokenKey(userId)),
      AuthService.generateToken({ sub: viewType }),
    ]);

    localStorage.setItem(tokenKey, tokenValue);
  }

  static async getViewTypeToken(userId: string) {
    const tokenKey = await AuthService.generateToken(this.getViewTypeTokenKey(userId));

    const tokenValueEncoded = localStorage.getItem(tokenKey);
    const tokenValueDecoded = tokenValueEncoded && jose.decodeJwt(tokenValueEncoded);

    return tokenValueDecoded?.sub;
  }

  static getGoalsTypeTokenKey(userId: string) {
    return { sub: `goalsType-${userId}` };
  }

  static async setGoalsTypeToken(userId: string, goalsType: GoalsType) {
    const [tokenKey, tokenValue] = await Promise.all([
      AuthService.generateToken(this.getGoalsTypeTokenKey(userId)),
      AuthService.generateToken({ sub: goalsType }),
    ]);

    localStorage.setItem(tokenKey, tokenValue);
  }

  static async getGoalsTypeToken(userId: string) {
    const tokenKey = await AuthService.generateToken(this.getGoalsTypeTokenKey(userId));

    const tokenValueEncoded = localStorage.getItem(tokenKey);
    const tokenValueDecoded = tokenValueEncoded && jose.decodeJwt(tokenValueEncoded);

    return tokenValueDecoded?.sub;
  }
}
