import { endpointUrl } from "../utils/general";
import axiosIntance from "./axios";

export const createUser = (payload: any) => {
  return axiosIntance.post(endpointUrl("auth/signup"), payload);
};

export const getUser = (id: string) => {
  return axiosIntance.get(endpointUrl(`users/${id}`));
};

export const getSelectUserOptions = () => {
  return axiosIntance.get(endpointUrl("users/select-options"));
};

export const updateUser = (id: string, payload: any) => {
  return axiosIntance.patch(endpointUrl(`users/${id}`), payload);
};

export const getConsistency = (id: string) => {
  return axiosIntance.get(endpointUrl(`users/consistency/${id}`));
};

export const getUsers = (skip: number, search: string) => {
  return axiosIntance.get(endpointUrl(`users/all?skip=${skip}&search=${search}`));
};
