import { FC, Fragment, ReactNode, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Bars3BottomLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as CodeVallyLogo } from "../../../icons/codevally-logo.svg";
import { ReactComponent as GoalIcon } from "../../../icons/goal.svg";

import { classNames } from "../../../utils/general";
import { ROUTE_GOALS, ROUTE_USER } from "../../../utils/constants";
import LogoutModal from "../../modals/logout/LogoutModal";
import { useGoalContext } from "../../../context/GoalProvider";
import { useAuthContext } from "../../../context/AuthProvider";
import SearchUserList from "../../pages/search-user-list/SearchUserList";
import NotificationSlider from "../notification-slider/NotificationSlider";
import NotificationIcon from "../notification-icon/NotificationIcon";

const navigation = [{ name: "Goals", path: ROUTE_GOALS, icon: GoalIcon }];

const userNavigation = [{ name: "Profile" }, { name: "Log out" }];

const Sidebar: FC<{ children: ReactNode }> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [openLogoutModal, setOpenLogoutModal] = useState<boolean>(false);
  const [openNotificationSlider, setOpenNotificationSlider] = useState<boolean>(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { goalExpertiseView } = useGoalContext();

  // loaded is used to re-render the SearchUserList whenever user updates his profile
  const { user, loaded } = useAuthContext();

  const userNavigationClickHandler = (index: number) => {
    switch (index) {
      case 0:
        navigate(`${ROUTE_USER}/${user?.id}`);
        break;

      case 1:
        setOpenLogoutModal(true);
        break;
    }
  };

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white p-5">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center justify-between px-4">
                    <CodeVallyLogo />
                  </div>

                  <div className="mt-16 flex flex-col flex-grow justify-between">
                    <div>
                      <nav className="space-y-1 px-2 pb-4">
                        {navigation.map((item) => (
                          <Link
                            key={item.name}
                            to={item.path === ROUTE_GOALS ? item.path + `?view=${goalExpertiseView}` : item.path}
                            className={classNames(
                              pathname.includes(item.path)
                                ? "bg-gray-100 text-gray-900 font-semibold"
                                : "text-gray-500 hover:bg-gray-50 hover:text-gray-900",
                              "group flex items-center px-6 py-3.5 text-lg font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className={classNames(
                                pathname.includes(item.path)
                                  ? "text-gray-900"
                                  : "text-gray-400 group-hover:text-gray-900",
                                "mr-4 flex-shrink-0 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            {item.name}

                            {pathname.includes(item.path) && (
                              <div className="w-1.5 h-14 bg-brand-500 rounded-full absolute -right-[3px] cursor-default" />
                            )}
                          </Link>
                        ))}
                      </nav>

                      <div className="px-1.5 h-[65vh]">{loaded && <SearchUserList />}</div>
                    </div>

                    <Menu as="div" className="relative">
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <div className="mx-2">
                          <Menu.Items className="absolute left-0 bottom-2 z-10 mt-2 w-full origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item, index) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <div
                                    onClick={() => userNavigationClickHandler(index)}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block py-2 px-4 text-sm text-gray-700"
                                    )}
                                  >
                                    {item.name}
                                  </div>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </div>
                      </Transition>

                      <div>
                        <Menu.Button className="w-full">
                          <div className="px-2 justify-self-start">
                            <div className="bg-gray-100 flex items-center gap-2.5 text-sm font-medium active::outline-none active:ring-2 active:ring-brand-500 active:ring-offset-2 rounded-lg px-5 py-3.5">
                              <span className="sr-only">Open user menu</span>

                              <img
                                className="h-8 w-8 rounded-full"
                                src={`${process.env.REACT_APP_IMAGE_ENDPOINT}/${user?.avatar}`}
                                alt="user"
                              />

                              <span className="truncate">{user?.name}</span>
                            </div>
                          </div>
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-80 md:flex-col relative">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-grow flex-col overflow-y-auto overflow-x-hidden border-r border-gray-200 bg-white p-5">
            <div className="flex flex-shrink-0 items-center justify-between px-4">
              <CodeVallyLogo />
            </div>

            <div className="mt-16 flex flex-grow flex-col gap-4 justify-between">
              <div>
                <nav className="space-y-1 px-2 pb-4">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.path === ROUTE_GOALS ? item.path + `?view=${goalExpertiseView}` : item.path}
                      className={classNames(
                        pathname.includes(item.path)
                          ? "bg-gray-100 text-gray-900 font-semibold"
                          : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                        "group flex items-center px-6 py-3.5 text-lg font-medium rounded-md"
                      )}
                    >
                      <item.icon
                        className={classNames(
                          pathname.includes(item.path) ? "text-gray-500" : "text-gray-400 group-hover:text-gray-500",
                          "mr-3 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      {item.name}

                      {pathname.includes(item.path) && (
                        <div className="w-1.5 h-14 bg-brand-500 rounded-full absolute -right-[3px] cursor-default" />
                      )}
                    </Link>
                  ))}
                </nav>

                <div className="px-1.5 h-[65vh]">{loaded && <SearchUserList />}</div>
              </div>

              <Menu as="div" className="relative">
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <div className="mx-2">
                    <Menu.Items className="absolute left-0 bottom-2 z-10 mt-2 w-full origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item, index) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <div
                              onClick={() => userNavigationClickHandler(index)}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block py-2 px-4 text-sm text-gray-700"
                              )}
                            >
                              {item.name}
                            </div>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </div>
                </Transition>

                <div>
                  <Menu.Button className="w-full">
                    <div className="px-2 justify-self-start">
                      <div className="bg-gray-100 flex items-center gap-2.5 text-sm font-medium active::outline-none active:ring-2 active:ring-brand-500 active:ring-offset-2 rounded-lg px-5 py-3.5">
                        <span className="sr-only">Open user menu</span>

                        <img
                          className="h-8 w-8 rounded-full"
                          src={`${process.env.REACT_APP_IMAGE_ENDPOINT}/${user?.avatar}`}
                          alt="user"
                        />

                        <span className="truncate">{user?.name}</span>
                      </div>
                    </div>
                  </Menu.Button>
                </div>
              </Menu>
            </div>
          </div>
        </div>

        <LogoutModal openLogoutModal={openLogoutModal} setOpenLogoutModal={setOpenLogoutModal} />

        <div className="flex flex-1 flex-col md:pl-80">
          <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow px-4">
            <button
              type="button"
              className="sticky top-0 w-fit z-10 border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>

            <div className="flex flex-1 items-center flex-row-reverse">
              <NotificationIcon setOpenNotificationSlider={setOpenNotificationSlider} />

              <NotificationSlider open={openNotificationSlider} setOpen={setOpenNotificationSlider} />
            </div>
          </div>

          <main className="flex-1">
            <div className="px-16 py-14">{children}</div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
