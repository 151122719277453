import { getConsistency, getSelectUserOptions, getUser, getUsers, updateUser } from "../apis/user";

export default class UserService {
  static async getSelectUserOptions() {
    const { data } = await getSelectUserOptions();
    return data;
  }

  static async updateUser(id: string, payload: any) {
    await updateUser(id, payload);
  }

  static async getConsistency(id: string) {
    const { data } = await getConsistency(id);
    return data;
  }

  static async getUser(id: string) {
    const { data } = await getUser(id);
    return data;
  }

  static async getUsers(skip: number, search: string) {
    const { data } = await getUsers(skip, search);
    return data;
  }
}
