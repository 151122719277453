import { Dispatch, FC, SetStateAction } from "react";
import { BellIcon } from "@heroicons/react/24/outline";

import Icon from "../icon/Icon";
import { useAuthContext } from "../../../context/AuthProvider";

type NotificationIconProps = {
  setOpenNotificationSlider: Dispatch<SetStateAction<boolean>>;
};

const NotificationIcon: FC<NotificationIconProps> = ({ setOpenNotificationSlider }) => {
  const { totalUnreadNotifications } = useAuthContext();

  return (
    <div
      className="flex max-w-xs items-center rounded-full bg-white text-sm group focus:outline-none"
      onClick={setOpenNotificationSlider.bind(this, true)}
    >
      <div className="relative group-hover:cursor-pointer">
        <Icon removeClass="h-5 w-5 fill-current" appendClass="h-7 w-7 group-hover:text-brand-500">
          <BellIcon />
        </Icon>

        {totalUnreadNotifications !== 0 && (
          <span className="absolute top-0 right-0 text-xs h-4 w-4 text-center text-white bg-brand-500 rounded-full">
            {totalUnreadNotifications}
          </span>
        )}
      </div>
    </div>
  );
};

export default NotificationIcon;
