import * as jose from "jose";
import { logout, signin } from "../apis/auth";
import { createUser } from "../apis/user";

const TOKEN = "Token";
const privateKey = "123";

export default class AuthService {
  static async signin(email: string, password: string, keepUserLoggedIn: boolean) {
    const { data } = await signin({ email, password, keepUserLoggedIn });

    this.setToken(data.accessToken);
  }

  static async signup(payload: any) {
    const { data } = await createUser(payload);
    return data;
  }

  static async logout() {
    await logout();
    this.removeToken();
  }

  static async generateToken(data: any) {
    return await new jose.SignJWT(data).setProtectedHeader({ alg: "HS256" }).sign(new TextEncoder().encode(privateKey));
  }

  static setToken(token: string) {
    localStorage.setItem(TOKEN, token);
  }

  static getToken() {
    return localStorage.getItem(TOKEN);
  }

  static removeToken() {
    localStorage.removeItem(TOKEN);
  }
}
