import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import * as jose from "jose";

import AuthService from "../services/Auth";
import { IUser } from "../types/IUser";
import UserService from "../services/User";
import { endpointUrl } from "../utils/general";
import NotificationService from "../services/Notification";

type AuthContextProps = {
  user: IUser | null;
  loaded: boolean;
  totalUnreadNotifications: number;
  loadUnreadNotificationsCount: () => void;
  getUser: () => void;
};

type AuthProviderProps = {
  children: ReactNode;
};

const AuthContext = createContext<AuthContextProps>({
  user: null,
  loaded: false,
  totalUnreadNotifications: 0,
  loadUnreadNotificationsCount: () => {},
  getUser: () => {},
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [user, setUser] = useState<IUser | null>(null);
  const [totalUnreadNotifications, setTotalUnreadNotifications] = useState<number>(0);

  const getUser = useCallback(async () => {
    try {
      setLoaded(false);

      const token = AuthService.getToken();

      if (!token) {
        setUser(null);
        setTotalUnreadNotifications(0);
        return;
      }

      const decodedToken = jose.decodeJwt(token);

      const { id, name, email, avatar } = await UserService.getUser(decodedToken.sub!);

      setUser({ id, name, email, avatar });
    } catch (err: any) {
      console.error(err);
      if (err?.response?.status === 401) AuthService.logout();
      // Getting multiple messages while resolving the expired token
      // show({ type: Type.error, message: errorMessage(err) });
    } finally {
      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const loadUnreadNotificationsCount = useCallback(async () => {
    try {
      const totalNotifications = await NotificationService.countUnread();

      setTotalUnreadNotifications(totalNotifications);
    } catch (err: any) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    if (!user?.id) return;

    loadUnreadNotificationsCount();

    const eventSource = new EventSource(endpointUrl(`notifications/event/${user.id}`), { withCredentials: true });

    eventSource.onmessage = ({ data }) => {
      setTotalUnreadNotifications((prevNotificationCount) =>
        data === prevNotificationCount ? prevNotificationCount : JSON.parse(data)
      );
    };

    return () => eventSource.close();
  }, [user?.id, loadUnreadNotificationsCount]);

  return (
    <AuthContext.Provider value={{ user, getUser, loaded, totalUnreadNotifications, loadUnreadNotificationsCount }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
