import { FC } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../ui/sidebar/Sidebar";

const SidebarLayout: FC = () => {
  return (
    <>
      <Sidebar>
        <Outlet />
      </Sidebar>
    </>
  );
};

export default SidebarLayout;
