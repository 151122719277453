export enum GoalDetailsTab {
  SubGoals = "Sub Goals",
  Activities = "Activities",
}

export enum GoalsType {
  All = "all",
  InProgress = "in-progress",
  Completed = "completed",
}

export type IGoal = {
  id: string;
  name: string;
  description: string;
  startDate: string;
  finishDate: string | null;
  deadline: string;
  tagId: string;
  progress: number;
  xp: number;
  parent?: string;
  userId: string;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  avatar: string;
};
